import * as React from 'react';
import { AppBar } from 'react-admin';
import LayoutUserMenu from "./LayoutUserMenu";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo:{
        backgroundImage: `url('logo_transparente.png')`,
        width:'231px',
        height:'39px'
    },
    logopeq:{
        backgroundImage: `url('logo_transparente.png')`,
        width:'131px',
        height:'39px'
    }
});

const LayoutHeaderBar = props => {
    const classes = useStyles();
    return (
            <AppBar {...props} userMenu={<LayoutUserMenu />} >
                <Typography
                    variant="h6"
                    className={classes.title}
                    id="react-admin-title"
                />
                <span alt="Logo" className={classes.logo} />
                <span className={classes.spacer} />
                
            </AppBar>
    );
};

export default LayoutHeaderBar;