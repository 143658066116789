
import polyglotI18nProvider from 'ra-i18n-polyglot';

import ptBrMessages from 'ra-language-pt-br';
// domain translations
import ptDomainBrMessages from '../i18n/pt-br';

const messages = {
    'pt-br': {...ptDomainBrMessages,...ptBrMessages} ,
  };
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'pt-br');
export default i18nProvider;