import { ListGuesser, FieldGuesser, CreateGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';
import { TextField, ReferenceField, DateInput, ReferenceInput, SelectInput } from 'react-admin';
import NumberFormat from 'react-number-format';


const SubscriptionTitle = ({ record }) => {
    return <span>Assinatura {record ? `"${record.id}"` : ''}</span>;
};

const Preco = ({record,...props}) => {
    return <NumberFormat value={record.monthlyPrice} isNumericString={true} displayType={'text'} format={currencyFormatter} />
}

export default function currencyFormatter(value) {
    if (!Number(value)) return "Gratuito";
  
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value / 100);
  
    return `${amount}`;
  }

export const SubscriptionList = props => (
    <ListGuesser {...props} title={<SubscriptionTitle />}>
        <TextField source="owner.name" label="Time" />
        <TextField source="plan.name" label="Plano" />
    </ListGuesser>
);

export const SubscriptionShow = props => (
    <ShowGuesser {...props} title={<SubscriptionTitle />}>
        <ReferenceField source="owner" reference="teams" >
            <TextField source={"name"} addLabel={true} label="Time" />
        </ReferenceField>
        <ReferenceField source="plan" reference="plans" >
            <TextField source={"name"} addLabel={true} label="Plano" />
        </ReferenceField>
        <FieldGuesser source={"startDate"} addLabel={true} />
        <FieldGuesser source={"endDate"} addLabel={true} />
        <Preco addLabel={true} label="Valor mensal" />
        <ReferenceField source="paymentMethod" reference="payment-methods" >
            <TextField source={"name"} addLabel={true} label="Método de pagamento" />
        </ReferenceField>
    </ShowGuesser >
);

export const SubscriptionEdit = props => (
    <EditGuesser {...props} title={<SubscriptionTitle />}>
        <ReferenceInput source="owner" label="Time" target={"@id"} reference="teams" >
            <SelectInput disabled optionText="name" valeutext={"@id"} />
        </ReferenceInput>
        <ReferenceInput source="plan" label="Plano" reference="plans" >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source={"startDate"} />
        <DateInput source={"endDate"} />
        <InputGuesser source={"monthlyPrice"} />
        <ReferenceInput source="paymentMethod" label="Método de pagamento" target={"id"} reference="payment-methods" >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);


export const SubscriptionCreate = props => (
    <CreateGuesser {...props} title="Criar nova assinatura">
        <ReferenceInput source="owner" target={"id"} reference="teams" >
            <SelectInput optionText="name" valueText="@id" />
        </ReferenceInput>
        <ReferenceInput source="plan" target={"id"} reference="plans" >
            <SelectInput optionText="name" valueText="@id" />
        </ReferenceInput>
        <InputGuesser source={"startDate"} />
        <InputGuesser source={"monthlyPrice"} />
        <ReferenceInput source="paymentMethod" target={"id"} reference="payment-methods" >
            <SelectInput optionText="name" valueText="@id" />
        </ReferenceInput>
    </CreateGuesser>
);