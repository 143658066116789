import { ListGuesser, FieldGuesser, CreateGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';


const PaymentMethodTitle = ({ record }) => {
    return <span>Meio de pagamento {record ? `"${record.name}"` : ''}</span>;
};

export const PaymentMethodList = props => (
    <ListGuesser {...props} title={<PaymentMethodTitle />}>
        <FieldGuesser source={"name"} />
    </ListGuesser>
);

export const PaymentMethodShow = props => (
    <ShowGuesser {...props} title={<PaymentMethodTitle />}>
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"subscriptions"} addLabel={true} />
    </ShowGuesser>
);


export const PaymentMethodEdit = props => (
    <EditGuesser {...props} title={<PaymentMethodTitle />}>
        <InputGuesser source={"name"} />
    </EditGuesser>
);

export const PaymentMethodCreate = props => (
    <CreateGuesser {...props} title={<PaymentMethodTitle />}>
        <InputGuesser source={"name"} />
    </CreateGuesser>
);