import Chart from "react-google-charts";

const InsightsTotalPerPhase = (data) => {
    const dados = data.data.slice();
    dados.splice(0,1)
    var max = 0;
    dados.forEach(getMaxFromArray);

    function getMaxFromArray(value, index, array){
        var currentMax = value[1];
        if (currentMax>max){
            max = currentMax;
        }
    }

    return (
        <div style={{ display: 'flex',flex: 1, margin: 10, marginBottom:30, maxWidth: 600, width: '30%'}}>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data.data}
                chartPackages={['corechart', 'controls']}
                controls={[
                  {
                    controlType: 'NumberRangeFilter',
                    options: {
                      filterColumnIndex: 1,
                      containerId: 'filter_div',
                      minValue: 0,
                      maxValue: max,
                    },
                  },
                ]}
                options={{
                    is3D: true,
                    // Material design options
                   // chart: {
                        title: 'Processos por Classe Judicial (última CJ)',
                  //      subtitle: 'Relação de processos por última classe judicial no histórico.',
                   // },
                   // bars: 'horizontal',
                    legend: { position: 'bottom' },
                    animation: {
                        duration: 1500,
                        easing: 'linear',
                        startup: true,
                      },
                }}
            />
        </div>
    );
};

export default InsightsTotalPerPhase;