import * as React from "react";
import { List, Datagrid, TextField, downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const exporterTwo = companies => {
    const companiesForExport = companies.map(company => {
        return { 'name' : company.name };
    });
    jsonExport(companiesForExport, {
        headers: ['name']
    }, (err, csv) => {
        downloadCSV(csv, 'companies');
    });
}

export const CompanyList = props => (
    <List {...props} exporter={exporterTwo}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
        </Datagrid>
    </List>
  );
  
