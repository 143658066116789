
import Chart from "react-google-charts";


const InsightsTotalPerJudgingBody = (data) => {
    const dados = data.data.slice();
    dados.splice(0,1)
    var max = 0;
    dados.forEach(getMaxFromArray);

    function getMaxFromArray(value, index, array){
        var currentMax = value[1];
        if (currentMax>max){
            max = currentMax;
        }
    }
    return (
        <div style={{ display: 'flex', flex: 1, margin: 10, marginBottom:30, maxWidth: 600,width: '30%' }}>
        <Chart
            width={'100%'}
            height={'500px'}
            chartType="BarChart"
            data={data.data}
            x
            chartPackages={['corechart', 'controls']}
            controls={[
              {
                controlType: 'NumberRangeFilter',
                options: {
                  filterColumnIndex: 1,
                  minValue: 0,
                  maxValue: max,
                },
              },
            ]}
            options={{
                title: 'Processos por Órgão Judicante',
                // Material design options
                chart: {
                    title: 'Processos por Ministro',
                    subtitle: 'Relação de processos por Ministro',
                },
                bars: 'horizontal',
                legend: { position: 'none' },
                
            }}
        />
    </div>
    );
};
////
//title: 'Processos por Órgão Judicante',
//subtitle: 'Relação de processos por órgão judicante',
export default InsightsTotalPerJudgingBody;