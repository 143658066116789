import { useState, useEffect } from 'react';
import InsightsTotalLawsuits from './InsightsTotalLawsuits';
import InsightsTotalPerPhase from './InsightsTotalPerPhase';
import InsightsTotalPerRapporteur from './InsightsTotalPerRapporteur';
import InsightsTotalPerJudgingBody from './InsightsTotalPerJudgingBody';
import { useListContext } from 'react-admin';

const styles = {
    flex: { display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'},
    flexColumn: { display: 'flex', flexFlow: 'row wrap',flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const InsightsDashboards = () => {
    const [dataLoadingStatus, setDataLoadingStatus] = useState('loading');
    const [chartInsightPerRapporteurData, setChartInsightPerRapporteurData] = useState([]);
    const [chartInsightPerPhaseData, setChartInsightPerPhaseData] = useState([]);
    const [chartInsightPerJudgingBodyData, setChartInsightPerJudgingBodyData] = useState([]);
    const { filterValues } = useListContext();
    let url = '';
    if (Object.keys(filterValues).length === 0) {
        url = `${process.env.REACT_APP_API_STATS_ENDPOINT}`;
    } else {
        let queryString = encodeURIComponent(JSON.stringify(filterValues));
        url = `${process.env.REACT_APP_API_STATS_ENDPOINT}?filter=${queryString}`;
    }

    useEffect(() => {
        const request = new Request(
            url,
            {
                method: "GET",
                headers: new Headers({ "Content-Type": "application/ld+json" }),
            }
        );
        fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            }).then((result) => {
                setDataLoadingStatus('ready')
                setChartInsightPerRapporteurData(result['hydra:member'][0]['lawsuitsPerRapporteur']);
                setChartInsightPerPhaseData(result['hydra:member'][0]['lawsuitsPerPhase']);
                setChartInsightPerJudgingBodyData(result['hydra:member'][0]['lawsuitsPerJudgingBody']);
            });
    }, [url])

    return (
        <>
            {dataLoadingStatus === 'loading' ? <div>Loading Chart</div> :
                <div style={styles.flex} >
                    <div style={styles.leftCol}>
                        <InsightsTotalLawsuits />
                        <div style={styles.flex}>
                            <InsightsTotalPerPhase data={chartInsightPerPhaseData} />
                            <InsightsTotalPerRapporteur data={chartInsightPerRapporteurData} />
                            <InsightsTotalPerJudgingBody data={chartInsightPerJudgingBodyData} />
                        </div>
                    </div>
                </div >
            }
        </>
    );
}

export default InsightsDashboards;
