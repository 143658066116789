import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { ReferenceField, TextField } from 'react-admin';
import LawsuitsListTstUrlLinkField from '../Components/LawsuitsListTstUrlLinkField';
import LawsuitList from '../Components/LawsuitList';  // Importando o novo componente de listagem

// Título do Lawsuit
const LawsuitTitle = ({ record }) => {
    return <span>Processo {record ? `"${record.number}"` : ''}</span>;
};

// Listagem de lawsuits
export { LawsuitList };  // Exporta o LawsuitList diretamente

// Visualização de um lawsuit
export const LawsuitShow = props => (
    <ShowGuesser {...props} title={<LawsuitTitle />}>
        <FieldGuesser source="number" addLabel={true} label='Número' />
        <FieldGuesser source="rapporteur" addLabel={true} label='Relator' />
        <FieldGuesser source="judgingBody" addLabel={true} label='Órgão Judicante' />
        <FieldGuesser source="fullHistory" addLabel={true} label='Histórico da classe judicial' />
        <FieldGuesser source="noticeDate" addLabel={true} label='Data de autuação' />
        <ReferenceField source="employer" reference="companies" addLabel={true} label='Empregador'>
            <TextField source="name" />
        </ReferenceField>
        <LawsuitsListTstUrlLinkField source="url" />
    </ShowGuesser>
);
