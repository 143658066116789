import React, { useState } from 'react'; 
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Filter,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    downloadCSV,
    FunctionField,
    Link
} from 'react-admin';
import { Button, Box, Typography } from '@material-ui/core';
import InsightsDashboards from './InsightsDashboards';
import jsonExport from 'jsonexport/dist';
import LawsuitEvents from './LawsuitEvents'; // Importar o componente de eventos

// Função de exportação
const exporter = (lawsuits, fetchRelatedRecords) => {
    fetchRelatedRecords(lawsuits, 'employer', 'companies').then(companies => {
        const data = lawsuits.map(lawsuit => ({
            'Número': lawsuit.number,
            'Relator': lawsuit.rapporteur,
            'Órgão Judicante': lawsuit.judgingBody,
            'Data de autuação': lawsuit.noticeDate,
            'Fase': lawsuit.fullHistory,
            'Empresa': companies[lawsuit.employer]?.name || 'N/A',
            'Url': lawsuit.url
        }));
        jsonExport(data, {}, (err, csv) => {
            downloadCSV(csv, 'lawsuits');
        });
    });
};

// Filtro de lawsuits
const LawsuitFilter = (props) => (
    <Filter {...props}>
        <ReferenceArrayInput
            label="Organização"
            source="employer"
            reference="companies"
            perPage={100}
            filterToQuery={searchText => searchText.trim().length > 2 ? ({ name: searchText }) : {}}
        >
            <AutocompleteArrayInput optionText="name" shouldRenderSuggestions={(val) => val.trim().length > 2} />
        </ReferenceArrayInput>
    </Filter>
);

// Componente para expandir a linha e mostrar os eventos
const ExpandableLawsuitRow = ({ record }) => (
    <Box margin={2}>
        <Typography variant="h6">Eventos do Processo</Typography>
        <LawsuitEvents lawsuitId={record.id} />
    </Box>
);

const LawsuitList = (props) => {
    return (
        <List {...props} exporter={exporter} filters={<LawsuitFilter />}>
            <>
                <InsightsDashboards /> {/* Dashboards */}
                <Datagrid expand={<ExpandableLawsuitRow />}> {/* Usamos o expand aqui */}
                    <TextField source="number" label="Número" />
                    <TextField source="judgingBody" label="Órgão Judicante" />
                    <TextField source="fullHistory" label="Histórico" />
                    <ReferenceField source="employer" reference="companies">
                        <TextField source="name" label="Empregador" />
                    </ReferenceField>

                    {/* Link para os detalhes do processo */}
                    <FunctionField
                        label="Ações"
                        render={record => (
                            <Button
                                component={Link}
                                to={`/lawsuits/${encodeURIComponent(record['@id'])}/show`}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                style={{ minWidth: '80px', padding: '4px 8px' }}
                            >
                                Detalhes
                            </Button>
                        )}
                    />
                </Datagrid>
            </>
        </List>
    );
};

export default LawsuitList;
