import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
    useTranslate,
    usePermissions,
    DashboardMenuItem,
    MenuItemLink,
} from 'react-admin';
import { useStore } from 'react-redux'
import LayoutSubMenu from './LayoutSubMenu.js';
//icons
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PersonIcon from '@material-ui/icons/Person';
import UserIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CreditCardIcon from '@material-ui/icons/CreditCard';



const LayoutMenu = ({ onMenuClick, logout, dense = false }) => {
    const { permissions } = usePermissions();
    const store = useStore();
    const [state, setState] = useState({
        menuInsights: true,
        menuAdmin: true,
        menuOps: true,
    });
    const translate = useTranslate();
    const open = store.getState().admin.ui.sidebarOpen;
    useSelector((AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box mt={1}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            {permissions === 'ROLE_ADMIN' ?
                <LayoutSubMenu
                    handleToggle={() => handleToggle('menuAdmin')}
                    isOpen={state.menuAdmin}
                    sidebarIsOpen={open}
                    name="pos.menu.admin"
                    icon={<PersonIcon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={`/users`}
                        primaryText={translate(`resources.users.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<PersonIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/teams`}
                        primaryText={translate(`resources.teams.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<UserIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/plans`}
                        primaryText={translate(`resources.plans.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<LocalOfferIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/subscriptions`}
                        primaryText={translate(`resources.subscriptions.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<ShoppingBasketIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> 
                    <MenuItemLink
                        to={`/payment-methods`}
                        primaryText={translate(`resources.payment_methods.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<CreditCardIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                </LayoutSubMenu> : null}
            <LayoutSubMenu
                handleToggle={() => handleToggle('menuInsights')}
                isOpen={state.menuInsights}
                sidebarIsOpen={open}
                name="pos.menu.insights"
                dense={dense}
                icon={<ApartmentIcon />}
            >
                {/* <MenuItemLink
                    to={`/companies`}
                    primaryText={translate(`resources.companies.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ApartmentIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                /> */}
                <MenuItemLink
                    to={`/lawsuits`}
                    primaryText={translate(`resources.lawsuits.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<BusinessCenterIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </LayoutSubMenu>
            <LayoutSubMenu
                handleToggle={() => handleToggle('menuOps')}
                isOpen={state.menuOps}
                sidebarIsOpen={open}
                name="pos.menu.ops"
                dense={dense}
                icon={<AccountBalanceIcon />}
            >
                <MenuItemLink
                    to={`/scraps`}
                    primaryText={translate(`resources.scraps.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<AccountBalanceIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </LayoutSubMenu>
        </Box>
    );
};

export default LayoutMenu;