import React from 'react';
import { useStore } from 'react-redux'
import { Redirect, Route } from "react-router-dom";
import { hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra, useIntrospection } from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import jwtDecode from "jwt-decode";

// Data Provider
const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;

const getHeaders = () => localStorage.getItem("token") ? {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};


const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });


const RedirectToLogin = () => {
  const introspect = useIntrospection();
  const store = useStore();
  const path = store.getState().router.location.pathname;
  if (localStorage.getItem("token") || path === "/forgot-password" || path === "/reset-password") {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};


const apiDocumentationParser = async (docspoint) => {
  try {
    const { api } = await parseHydraDocumentation(docspoint, {
      headers: getHeaders,
    });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      localStorage.removeItem("token");
      return {
        api: result.api,
        customRoutes: [
          <Route path="/" component={RedirectToLogin} />
        ],
      };
    }

    throw result;
  }
};


const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);


const addUserProfileOverrides = (dataProvider) => ({
  ...dataProvider,
  getUserProfile() {
    const profile = sessionStorage.getItem("userProfile");
    if (!profile) {
      return getUserProfileOnServer()
    }
  },
  async updateUserProfile({ data }) {
    updateUserProfileOnServer(data);
    return Promise.resolve({data});
  }
});

const updateUserProfileOnServer = (data) => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const request = new Request(
    `${process.env.REACT_APP_API_ENTRYPOINT}/users/${decodedToken.id}`,
    {
      method: "PUT",
      mode: 'cors',
      body: JSON.stringify({ fullName: data.fullName }),
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept": "application/ld+json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }),
    }
  );

  return fetch(request)
    .then(response => response.json())
    .then(jsondata => {
      return jsondata
    }).catch(function (error) {
      return Promise.reject(`Looks like there was a problem: ${error}`)
    });
}

const getUserProfileOnServer = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const request = new Request(
    `${process.env.REACT_APP_API_ENTRYPOINT}/users/${decodedToken.id}`,
    {
      method: "GET",
      mode: 'cors',
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept": "application/ld+json",
        "Authorization": `Bearer ${token}`,
      }),
    }
  );

  return fetch(request)
    .then(response => response.json())
    .then(jsondata => {
      return Promise.resolve({data: {fullName: jsondata.fullName, id: jsondata.["@id"], email: jsondata.email, team:jsondata.team}});
    }).catch(function (error) {
      return Promise.reject(`Looks like there was a problem: ${error}`)
    });
};


export default addUserProfileOverrides(dataProvider);

