import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { useTranslate, useListContext } from 'react-admin';
import DashboardCardWithIconComponent from './DashboardCardWithIconComponent';


const InsightsTotalLawsuits = () => {
    const translate = useTranslate();
    const { total } = useListContext();
    return (
        <DashboardCardWithIconComponent
            to='/'
            icon={BusinessCenterIcon}
            title={translate('pos.insights.total_lawsuits')}
            subtitle={total}
        />
    );
};

export default InsightsTotalLawsuits;