
import ApartmentIcon from '@material-ui/icons/Apartment';
import { useTranslate } from 'react-admin';

import DashboardCardWithIconComponent from './DashboardCardWithIconComponent';


const DashboardTotalCompanies = ({value}) => {
    const translate = useTranslate();
    return (
        <DashboardCardWithIconComponent
            to="/commands"
            icon={ApartmentIcon}
            title={translate('pos.dashboard.total_companies')}
            subtitle={value}
        />
    );
};

export default DashboardTotalCompanies;