import { ListGuesser, FieldGuesser, CreateGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';
import { ReferenceArrayField, TextField, SingleFieldList, ChipField, ReferenceArrayInput, SelectArrayInput, BooleanInput } from 'react-admin';

const TeamsTitle = ({ record }) => {
    return <span>Time {record ? `"${record.name}"` : ''}</span>;
};


const CreateStripe = ({ record, ...props }) => {
    if(record.stripeCustomerId === undefined){
        return <BooleanInput label="Create stripe customer?" source="createStripe"/>
    } else {
        return <TextField source={record.stripeCustomerId} />
    }
    
   
}

export const TeamsList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"} />
        <FieldGuesser source={"createdAtAgo"} />
    </ListGuesser>
);

export const TeamsShow = props => (
    <ShowGuesser {...props} title={<TeamsTitle />}>
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
        <ReferenceArrayField source="users" target={"id"} reference={"users"}>
            <SingleFieldList>
                <ChipField source="fullName" />
            </SingleFieldList>
        </ReferenceArrayField>
        <FieldGuesser source={"subscription"} addLabel={true} />
        <FieldGuesser addLabel={true} label="Stripe Customer ID" source={"stripeCustomerId"} />
    </ShowGuesser>
);

export const TeamsEdit = props => {
    return (
    <EditGuesser {...props} title={<TeamsTitle />}>
        <InputGuesser source={"name"} />
        <ReferenceArrayInput source="users" target={"id"} reference="users" >
            <SelectArrayInput optionText="fullName" valueText="@id" />
        </ReferenceArrayInput>
        <CreateStripe />
    </EditGuesser>
)};

export const TeamsCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"} />
        <ReferenceArrayInput source="users" target={"id"} reference="users" >
            <SelectArrayInput optionText="fullName" valueText="@id" />
        </ReferenceArrayInput>
        <InputGuesser source={"createStripe"} />
    </CreateGuesser>
);
