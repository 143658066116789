import { ListGuesser, FieldGuesser, CreateGuesser, ShowGuesser, EditGuesser, InputGuesser } from '@api-platform/admin';

import NumberFormat from 'react-number-format';

const PlanTitle = ({ record }) => {
    return <span>Plano {record ? `"${record.name}"` : ''}</span>;
};

const Preco = ({ record, ...props }) => {
    return <NumberFormat value={record.price} isNumericString={true} displayType={'text'} format={currencyFormatter} />
}

function currencyFormatter(value) {
    if (!Number(value)) return "Gratuito";

    const amount = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
    }).format(value / 100);

    return `${amount}`;
}

export const PlansList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"} />
        <Preco addLabel={true} label="Preço" />
    </ListGuesser>
);



export const PlansShow = props => (
    <ShowGuesser {...props} title={<PlanTitle />}>
        <FieldGuesser source={"name"} addLabel={true} label="Nome" />
        <FieldGuesser source={"description"} addLabel={true} label="Descrição" />
        <Preco addLabel={true} label="Preço" />
        <FieldGuesser addLabel={true} label="Stripe ID" source={"stripeId"} />
    </ShowGuesser>
);

export const PlansEdit = props => (
    <EditGuesser {...props} title={<PlanTitle />}>
        <InputGuesser source={"name"} />
        <InputGuesser source={"description"} />
        <InputGuesser source={"price"} />
        <InputGuesser source={"createStripe"} />
    </EditGuesser>
);

export const PlansCreate = props => (
    <CreateGuesser {...props} title="Planos">
        <InputGuesser source={"name"} />
        <InputGuesser source={"description"} />
        <InputGuesser source={"price"} />
        <InputGuesser source={"createStripe"} />
    </CreateGuesser>
);
