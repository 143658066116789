import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import {
  TabbedForm,
  FormTab,
  TextInput,
  required,
  useDataProvider,
  useNotify,
  SaveContextProvider,
  TextField,
  ReferenceField,
  FormDataConsumer
} from "react-admin";
import { useProfile } from "../providers/ProfileProvider";


export const ProfileEdit = ({ staticContext, ...props }) => {
  const [record, setRecord] = useState({ 'fullName': 'Waiting' });
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState();
  const { refreshProfile } = useProfile();
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    dataProvider.getUserProfile().then((data) => {
      setRecord(data.data)
      setLoaded(true);
    })
  }, [dataProvider]
  )

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider.updateUserProfile(
        { data: values },
        {
          onSuccess: ({ data }) => {
            setSaving(false);
            notify("Your profile has been updated", "info", {
              _: "Your profile has been updated"
            });
            refreshProfile();
          },
          onFailure: () => {
            setSaving(false);
            notify(
              "A technical error occured while updating your profile. Please try later.",
              "warning",
              {
                _:
                  "A technical error occured while updating your profile. Please try later."
              }
            );
          }
        }
      );
    },
    [dataProvider, notify, refreshProfile]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving
    }),
    [saving, handleSave]
  );

  if (!loaded) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <TabbedForm save={handleSave} record={record ? record : {}}>
        <FormTab label="resumo">
          <TextInput disabled source="id" validate={required()} />
          <TextInput source="fullName" validate={required()} />
          <TextField source="email" />
          <TextField source="team" />
        </FormTab>
        <FormTab label="assinatura"></FormTab>
      </TabbedForm>
    </SaveContextProvider>
  );
};
