import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
        fontSize: '10px',
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2,
    },
});

const LawsuitsListTstUrlLinkField = ({ record = {}, source }) => {
    const classes = useStyles();
    return (
        <>
            <span className={classes.link}>Acesso em {record.updatedAt}.</span>
            <a target="_blank" rel="noreferrer" href={record[source]} className={classes.link}>
                 Disponível no site do TST
            <LaunchIcon className={classes.icon} />
            </a>
        </>
    )
}


export default LawsuitsListTstUrlLinkField;