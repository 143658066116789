import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
// import red from '@material-ui/core/colors/red';


export const themeProvider = merge({}, defaultTheme, {
    palette: {
        primary:  {
            light: '#406c99',
            main: '#01426a',
            dark: '#001b40',
            contrastText: '#fff',
          }, 
        secondary: {
            main: '#01426a'
          },
    },
    typography: {
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    MuiAvatar: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            variant: 'square',
          },
        },
    }
});


export default themeProvider;