import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { useTranslate } from 'react-admin';

import DashboardCardWithIconComponent from './DashboardCardWithIconComponent';


const DashboardTotalLawsuits = ({value}) => {
    const translate = useTranslate();
    return (
        <DashboardCardWithIconComponent
            to="/commands"
            icon={BusinessCenterIcon}
            title={translate('pos.dashboard.total_lawsuits')}
            subtitle={value}
        />
    );
};

export default DashboardTotalLawsuits;