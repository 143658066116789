import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { List, ListItem, ListItemText, Divider, Typography } from '@material-ui/core';

const LawsuitEvents = ({ lawsuitId }) => {
    const dataProvider = useDataProvider();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            setLoading(true);
            try {
                const { data } = await dataProvider.getList('events', {
                    filter: { lawsuit: lawsuitId },
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'date', order: 'DESC' }
                });
                setEvents(data);
            } catch (err) {
                setError('Erro ao carregar eventos');
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [lawsuitId, dataProvider]);

    if (loading) return <p>Carregando eventos...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            {events.length === 0 ? (
                <Typography variant="body2" color="textSecondary">Não foram encontrados eventos para este processo.</Typography>
            ) : (
                <List>
                    {events.map(event => (
                       <React.Fragment key={event.id}>
                       <ListItem>
                           <ListItemText
                               primary={(
                                   <>
                                       <Typography variant="caption" color="textSecondary">
                                           <strong>{new Date(event.date).toLocaleDateString()}</strong> – {event.description}
                                       </Typography>
                                   </>
                               )}
                           />
                       </ListItem>
                       <Divider component="li" />
                   </React.Fragment>              
                    ))}
                </List>
            )}
        </div>
    );
};

export default LawsuitEvents;
