import * as React from "react";
import { List, Datagrid, TextField, SimpleForm, DateField, Create, TextInput, ReferenceField } from 'react-admin';
import { usePermissions } from 'react-admin';

export const ScrapsList = props => {
    const { permissions } = usePermissions();
    return (
    <List {...props}>
        <Datagrid>
            <TextField source={"queryString"} />
            <TextField source={"totalItems"} />
            <TextField source={"processedBasicInfo"} />
            <TextField source={"processedDetailedInfo"} />
            {permissions === 'ROLE_ADMIN' && 
            <ReferenceField source={"team"} reference={"teams"}>
                <TextField source={"name"} />
            </ReferenceField>
            }
            <DateField source={"updatedAt"} />
        </Datagrid>

    </List>
)};

export const ScrapCreate = props => (
    <Create {...props} title="Scrap">
        <SimpleForm redirect="list">
            <TextInput source={"queryString"} />
        </SimpleForm>
    </Create>
);