import React from 'react';
import { Route } from "react-router-dom";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
// Components
import Dashboard from './Components/Dashboard'
import Layout from './Components/AppLayout';
import LayoutMenu from './Components/LayoutMenu';
import CustomLoginPage from './Components/CustomLoginPage';
import LoginForgotPassword from './Components/LoginForgotPassword';
import LoginResetPassword from './Components/LoginResetPassword';
// Resources
import { CompanyList } from './resources/companies'
import { LawsuitList, LawsuitShow } from './resources/lawsuits'
import { ScrapsList, ScrapCreate } from './resources/scraps'
import { UsersList, UserEdit, UserCreate} from './resources/users'
import { ProfileEdit } from './resources/profile';
import { TeamsList, TeamsShow, TeamsEdit, TeamsCreate } from './resources/teams';
import { PaymentMethodList, PaymentMethodShow, PaymentMethodEdit, PaymentMethodCreate} from './resources/payment_methods';
import { PlansList, PlansShow, PlansEdit, PlansCreate } from './resources/plans';
import { SubscriptionList, SubscriptionShow, SubscriptionEdit, SubscriptionCreate } from './resources/subscriptions';

// Providers
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import themeProvider from './providers/themeProvider';
import i18nProvider from './providers/i18nProvider';

const title = 'HELIEIA'

const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;
const App =  () => (
  <HydraAdmin dashboard={Dashboard}
    layout={Layout}
    loginPage={CustomLoginPage}
    authProvider={authProvider}
    menu={LayoutMenu} 
    theme={themeProvider}
    title={title}
    dataProvider={dataProvider}
    entrypoint={entrypoint}
    i18nProvider={i18nProvider}
    disableTelemetry
    customRoutes={[
      <Route key="my-profile" path="/my-profile" component={ProfileEdit} />,
      <Route path="/forgot-password" render={(props) => (<LoginForgotPassword {...props} theme={themeProvider} />)}  noLayout />,
      <Route path="/reset-password" render={(props) => (<LoginResetPassword {...props} theme={themeProvider} />)}  noLayout />,
    ]}
  >
    {permissions => [
       
      <ResourceGuesser name={"companies"} list={CompanyList} options={{ label: 'Empresas' }} />,
      <ResourceGuesser name={"lawsuits"} list={LawsuitList} show={LawsuitShow} edit={null} options={{ label: 'Processos' }} />,
      <ResourceGuesser name={"scraps"} list={ScrapsList} create={ScrapCreate} options={{ label: 'Solon' }} />,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"users"} list={UsersList} edit={UserEdit} create={UserCreate} options={{ label: 'Usuários' }} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"teams"} list={TeamsList} show={TeamsShow} edit={TeamsEdit} create={TeamsCreate} /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"plans"} list={PlansList} show={PlansShow} edit={PlansEdit} create={PlansCreate}  /> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"subscriptions"} list={SubscriptionList} show={SubscriptionShow} edit={SubscriptionEdit} create={SubscriptionCreate}/> : null,
      permissions === 'ROLE_ADMIN' ? <ResourceGuesser name={"payment-methods"} list={PaymentMethodList} show={PaymentMethodShow} edit={PaymentMethodEdit} create={PaymentMethodCreate}/> : null,
    ]}
  </HydraAdmin>
);
export default App;