import {
    useState,
    useEffect,
    useCallback
} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import Welcome from './DashboardWelcomeCard';
import DashboardTotalLawsuits from './DashboardTotalLawsuits';
import DashboardTotalCompanies from './DashboardTotalCompanies';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};


const Spacer = () => <span style={{ width: '1em' }} />;

const Dashboard = ({permissions}) => {
    const dataProvider = useDataProvider();
    const version = useVersion();
    const [state, setState] = useState({
        lawsuitsNmb: '0',
        companiesNmb: '0',
    });

    const fetchTotalLawsuits = useCallback(async () => {
        const { total: lawsuits } = await dataProvider.getList (
            'lawsuits',
            {
                sort: {  },
                pagination: { page: 1, perPage: 1 },
            }
        );
        setState(state => ({ ...state, lawsuitsNmb: lawsuits }));
    }, [dataProvider]);
    
    const fetchTotalCompanies = useCallback(async () => {
        const { total: companies } = await dataProvider.getList (
            'companies',
            {
                sort: {  },
                pagination: { page: 1, perPage: 1 },
            }
        );
        setState(state => ({ ...state, companiesNmb: companies }));
    }, [dataProvider]);

    useEffect(() => {
        fetchTotalLawsuits();
        fetchTotalCompanies();
    }, [version,fetchTotalLawsuits,fetchTotalCompanies]); 

    const { lawsuitsNmb, companiesNmb } = state;
    return (
        <>
            <Welcome />
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <DashboardTotalLawsuits value={lawsuitsNmb} />
                        <Spacer />
                        <DashboardTotalCompanies value={companiesNmb} />
                    </div>
                </div>
            </div>
        </>
    );

}
export default Dashboard;